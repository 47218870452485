import { COUNTABLE_SCREENS } from '@sharedConstants';
import { configureStore } from '@reduxjs/toolkit';
import soundsMiddleware from 'redux-sounds';
import routeReducer, {
  initialRouteState,
  RouteState,
} from './slices/routeSlice';
import gameStateReducer, {
  initialGameState,
  GameState,
} from './slices/gameStateSlice';
import loggingReducers, {
  initialLoggingState,
  LoggingState,
} from './slices/loggingSlice';
import levellingReducer, {
  initialLevellingState,
  LevelState,
} from './slices/levellingSlice';
import loggingMiddleware from './middleware/loggingMiddleware';

const loadedSoundsMiddleware = soundsMiddleware({
  // TODO: as an experiment, default sound effects are OFF
  // stub basically all the music/sounds as we want to load it on demand
  // items that load on home page land
  // Button: sounds.Button,
  // all lazy loaded items are here, but are stubbed with the button sound for now
  // Music: sounds.Button,
  // Incorrect: sounds.Button,
  // Correct: sounds.Button,
});
const loadedLoggingMiddleware = loggingMiddleware();

export function getInitialState({ allRoutes = [], pooledRoutes = [] } = {}) {
  return {
    route: {
      ...initialRouteState,
      // these are loaded at gatsby build time, but allRoutes will change when app inits based on game config
      allRoutes,
      pooledRoutes,
      allOriginalRoutes: allRoutes,
    },
    gameState: {
      ...initialGameState,
    },
    levelling: {
      ...initialLevellingState,
      // from our routes, count the number of claims
      totalClaims: allRoutes.filter(e =>
        COUNTABLE_SCREENS.includes(e.typeOfScreen),
      ).length,
    },
    logging: {
      ...initialLoggingState,
    },
  };
}

const store = ({ allRoutes = [], pooledRoutes = [] } = {}) =>
  configureStore({
    preloadedState: getInitialState({ allRoutes, pooledRoutes }),
    reducer: {
      route: routeReducer,
      gameState: gameStateReducer,
      levelling: levellingReducer,
      logging: loggingReducers,
    },
    middleware: getDefaultMiddleware => {
      // ignore middleware for tests
      if (process.env.NODE_ENV === 'test') {
        return getDefaultMiddleware();
      }
      return getDefaultMiddleware().concat([
        loadedSoundsMiddleware,
        loadedLoggingMiddleware,
      ]);
    },
  });
const dummyStore = store();
export type RootState = ReturnType<typeof dummyStore.getState>;
export type AppDispatch = typeof dummyStore.dispatch;

export default store;
