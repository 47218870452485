/*
 Leveling system has multiple prongs that I thought would be saner to co-locate
*/
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import gameConfig from '@content/gameconfig';

export type LevelState = {
  /** keep track of what claim number you are on * */
  claimIndex: number;
  /** useful for the count displayed in the header of total q answered */
  claimsAnswered: number;
  /** this number might be different than points, so it gets its own value */
  claimsAnsweredCorrectly: number;
  /** our total claims */
  totalClaims: number;
  /** each time you level up, this value goes back down to zero (its a rolling count) */
  pointsForLevel: number;
  /** determined by airtable, but holds what is our next milestone (to make us progress) */
  pointsThreshold: number;
  /** once you meet a certain points threshold, we increment this value * */
  currentLevel: number;
}

interface QuestionAnsweredProps {
  userAnsweredCorrectly: boolean;
  givesPoints: boolean;
}

const firstLevelPointsRequired = gameConfig.Levels[0].Points_Required;

const moveToNextLevelBracket = (state: LevelState) => {
  // find our current threshold index, as its defined at build time
  const currentThresholdIndex = gameConfig.Levels.map(
    e => e.Points_Required,
  ).indexOf(state.pointsThreshold);
  // move our points threshold to the next "bracket"
  if (currentThresholdIndex < gameConfig.Levels.length - 1) {
    state.pointsThreshold =
      gameConfig.Levels[currentThresholdIndex + 1].Points_Required;
    // reset our cycling "points" for leveling purposes
    state.pointsForLevel = 0;
  }
};

export const initialLevellingState: LevelState = {
  claimIndex: 1,
  claimsAnswered: 0,
  claimsAnsweredCorrectly: 0,
  totalClaims: 0,
  pointsForLevel: 0,
  pointsThreshold: firstLevelPointsRequired,
  currentLevel: 0,
};

export const levellingSlice = createSlice({
  name: 'levelling',
  initialState: initialLevellingState,
  extraReducers: {
    'gameState/resetGame': state => ({
      ...initialLevellingState,
      totalClaims: state.totalClaims,
    }),
    'gameState/gameHasStarted': state => {
      // listen to when the game starts, and decide what initial level the user should be, so if its specified that the points threshold to be first is zero, then naturally, your current level is 1
      if (state.currentLevel === state.pointsThreshold) {
        // this is to avoid being "level 0" if the airtable config permits
        state.currentLevel = 1;
        moveToNextLevelBracket(state);
      }
    },
  },
  reducers: {
    setTotalClaims: (
      state,
      action: PayloadAction<{ newTotalClaims: number }>,
    ) => {
      state.totalClaims = action.payload.newTotalClaims;
    },
    questionHasBeenAnswered: (
      state,
      action: PayloadAction<QuestionAnsweredProps>,
    ) => {
      state.claimsAnswered += 1;
      if (action.payload.userAnsweredCorrectly) {
        // this is typically reserved for boolean questions
        state.claimsAnsweredCorrectly += 1;
        // increase our transient points count if the question gives points
        if (action.payload.givesPoints) {
          state.pointsForLevel += 1;
        }
      }
    },
    userProgressedToNextClaim: (
      state,
      action: PayloadAction<{
        isRecall: boolean;
      }>,
    ) => {
      // progressing means moving away from a claim (after you see the results)
      // recalls do not count as a new question, so therefore do not move you along the claim index
      if (state.claimIndex < state.totalClaims && !action.payload.isRecall) {
        state.claimIndex += 1;
      }
      if (state.pointsForLevel === state.pointsThreshold) {
        moveToNextLevelBracket(state);
        // bump the current level up
        state.currentLevel += 1;
      }
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  questionHasBeenAnswered,
  userProgressedToNextClaim,
  setTotalClaims,
} = levellingSlice.actions;

export default levellingSlice.reducer;
