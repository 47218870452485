import React from 'react';
import { GatsbyBrowser } from 'gatsby';
import { AnimatePresence } from 'framer-motion';
import wrapWithProvider from './src/redux/ProviderWrapper';
import Footer from './src/containers/Footer';
import Header from './src/containers/Header';
import NotificationSystem from './src/containers/NotificationSystem';
import './src/styles/global.css';
import { init } from './src/utils/navigationTimers';
import '@fontsource-variable/manrope';
import '@fontsource/luckiest-guy';
import '@fontsource-variable/inter';

export const wrapRootElement = wrapWithProvider;

init();

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({
  element,
}) => (
  <>
    <Header />
    <AnimatePresence exitBeforeEnter>{element}</AnimatePresence>
    <Footer />
    <NotificationSystem />
  </>
);
