import React from 'react';
import { Modal, Flex, IconButton, Label, Divider } from 'workspace-core-ui';
import { useOverlayTriggerState } from '@react-stately/overlays';
import Toggle from 'workspace-core-ui/Toggle';
import {
  setIsBackgroundMusicOn,
  setAreSoundEffectsOn,
} from '@slices/gameStateSlice';
import { useAppSelector, useAppDispatch } from '@hooks/redux-hooks';
import useTranslation from '@hooks/useTranslation';
import useCustomReducedMotion from '@hooks/useCustomReducedMotion';
import { logItem } from '@slices/loggingSlice';
import { addBackgroundMusic, addGameSounds } from '@slices/audioSlice';
import PairShareModal from './PairShareModal-v2';

const OptionsModalButton = (): JSX.Element => {
  const { t } = useTranslation();
  const overlayState = useOverlayTriggerState({});
  const dispatch = useAppDispatch();
  // control states
  const { isBackgroundMusicOn, areSoundEffectsOn } = useAppSelector(
    s => s.gameState,
  );
  const shouldReduceMotion = useCustomReducedMotion();

  return (
    <>
      <IconButton
        id="options-button"
        showOutline={false}
        onPress={() => overlayState.open()}
        iconName="music"
        aria-label="options"
        data-cy="optionsButton"
        aria-labelledby="options-button-label"
      />
      <Modal
        title={t('Options')}
        isOpen={overlayState.isOpen}
        onClose={overlayState.close}
        isDismissable
        showClose
        closeLabel={t('Close')}
        modalBodyStyles={{
          bottom: 4,
          left: ['unset', '16px', '16px'],
        }}
      >
        <Flex mt={5} as="form" flexDirection="column">
          <Flex width="100%" alignItems="center" justifyContent="space-between">
            <Label variant="l3"> {t('Sound Modal Music Toggle')}</Label>
            <Toggle
              aria-label={t('Sound Modal Music Toggle')}
              onLabel={t('On Label')}
              offLabel={t('Off Label')}
              reduceMotion={shouldReduceMotion}
              customDataCy="musicToggle"
              isSelected={isBackgroundMusicOn}
              onChange={isSelected => {
                if (isSelected) {
                  dispatch(addBackgroundMusic());
                }
                dispatch(
                  logItem({
                    collection_name: 'events',
                    event_type: 'toggle_settings',
                    target: `music_${isSelected ? 'on' : 'off'}`,
                  }),
                );
                dispatch(
                  setIsBackgroundMusicOn({
                    setTo: isSelected,
                  }),
                );
              }}
            />
          </Flex>
          <Divider my={1} />
          <Flex width="100%" alignItems="center" justifyContent="space-between">
            <Label variant="l3"> {t('Sound Modal SFX Toggle')}</Label>
            <Toggle
              aria-label={t('Sound Modal SFX Toggle')}
              onLabel={t('On Label')}
              offLabel={t('Off Label')}
              reduceMotion={shouldReduceMotion}
              customDataCy="soundToggle"
              isSelected={areSoundEffectsOn}
              onChange={isSelected => {
                // lazy loading internal sounds here
                if (isSelected) {
                  dispatch(addGameSounds());
                }
                dispatch(
                  logItem({
                    collection_name: 'events',
                    event_type: 'toggle_settings',
                    target: `sound_${isSelected ? 'on' : 'off'}`,
                  }),
                );
                dispatch(setAreSoundEffectsOn({ setTo: isSelected }));
              }}
            />
          </Flex>
          <Divider my={1} />
          <PairShareModal />
        </Flex>
      </Modal>
    </>
  );
};

export default OptionsModalButton;
